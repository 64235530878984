import React, { createContext, useEffect, useState } from "react";
import _ from "lodash";
// import { THEMES } from 'src/constants';

const defaultSettings = {
  direction: "rtl",
  responsiveFontSizes: true,
  language: "ar",
};

export const restoreSettings = () => {
  let settings = null;

  try {
    const storedData = window.localStorage.getItem("settings");
    if (storedData) {
      settings = JSON.parse(storedData);
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

//export const storeSettings = (settings) => {
//  window.localStorage.setItem("settings", JSON.stringify(settings));
//};

const SettingsContext = createContext({
  settings: defaultSettings,
  saveSettings: () => {},
});

export const SettingsProvider = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState(
    JSON.parse(window.localStorage.getItem("settings")) || defaultSettings
  );

  const handleSaveSettings = (update = {}) => {
    const portallang = window.localStorage.getItem("portallang");

    if (portallang) {
      setCurrentSettings(JSON.parse(portallang));
    } else {
      const mergedSettings = _.merge({}, currentSettings, update);
      setCurrentSettings(mergedSettings);
      window.localStorage.setItem("settings", JSON.stringify(mergedSettings));
    }
  };

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setCurrentSettings(restoredSettings);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    document.querySelector("html").lang = currentSettings.language;
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
