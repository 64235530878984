import { ReactComponent as helperArrow } from "./helperArrow.svg";
import { ReactComponent as enhanceImageIcon } from "./enhanceImageIcon.svg";
import { ReactComponent as openBookIcon } from "./openBookIcon.svg";
import { ReactComponent as accuracyEnhanceIcon } from "./accuracyEnhanceIcon.svg";
import { ReactComponent as reorderLinesIcon } from "./reorderLinesIcon.svg";
import { ReactComponent as disclaimerFileIcon } from "./disclaimerFileIcon.svg";
import { ReactComponent as disclaimerFileIconAccuracyEnhanceIcon } from "./disclaimerFileIconAccuracyEnhanceIcon.svg";
import { ReactComponent as segmentationIcon } from "./view-segmentation.svg";
import { ReactComponent as polygonIcon } from "./icon-polygon.svg";
import { ReactComponent as regionIcon } from "./icon-region.svg";
import { ReactComponent as baselineIcon } from "./icon-baseline.svg";
import { ReactComponent as retranscribeIcon } from "./retranscribeIcon.svg";
import { ReactComponent as tashkeelIcon } from "./custom-icon-tashkeel.svg";
import { ReactComponent as multimediaIcon } from "./multimediaIcon.svg";
import { ReactComponent as iconAddBaseline } from "./iconAddBaseline.svg";

const Icon = {
  helperArrow,
  enhanceImageIcon,
  openBookIcon,
  accuracyEnhanceIcon,
  reorderLinesIcon,
  disclaimerFileIcon,
  disclaimerFileIconAccuracyEnhanceIcon,
  segmentationIcon,
  polygonIcon,
  regionIcon,
  baselineIcon,
  retranscribeIcon,
  tashkeelIcon,
  iconAddBaseline,
  multimediaIcon,
};
export default Icon;
