import React from "react";
import PropTypes from "prop-types";
import useAuth from "../../hooks/useAuth";
import { Link, Navigate } from "react-router-dom";
import config from "../../utils/settings";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  if (!isAuthenticated) {
    return (
      <Link
        id="link-redirect-auth"
        to={`${config.REACT_APP_KEYCLOAK_URL}/realms/${config.REACT_APP_KEYCLOAK_REALM}`}
      />
    );
  } else {
    let token = localStorage.getItem("accessToken");
    if (token) {
      var decoded = jwt_decode(token);
      let arr = decoded.realm_access.roles.filter((e) => e.includes("ROLE"));
      if (arr.length == 0 || (arr.length == 1 && arr[0] == "ROLE_USER")) {
        return <Navigate to="/403" />;
      }
    }
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
