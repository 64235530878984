import React, { useState, useEffect } from "react";
import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import Icon from "@mdi/react";
import { mdiPlayCircleOutline } from "@mdi/js";
import AppTutorialVideo from "../dialogs/AppTutorialVideo";
import { CustomTooltip } from "../common/CustomTooltip";
import "../../assets/styles/components.css";

const HowToUseZinkiIcon = styled(Icon)(({ theme }) => ({
  color: `${theme.palette.primary.main} !important`,
}));

const HowToUseZinki = () => {
  const { user, setOnboardingData } = useAuth();
  const { t } = useTranslation();
  const [openHowToUseZinkiDialog, setOpenHowToUseZinkiDialog] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  useEffect(() => {
    if (
      !user.data.onboardingData.tutorialOnboarding.tooltipTuto &&
      user.data.onboardingData.tutorialOnboarding.isChecked
    ) {
      setOpenTooltip(true);
    }
  }, [user, openHowToUseZinkiDialog]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!user.data.onboardingData.tutorialOnboarding.isChecked) {
        setOpenHowToUseZinkiDialog(true);
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [user]);

  const handleOpenHowToUseZinkiDialog = () => {
    setOpenHowToUseZinkiDialog(true);
  };
  const handleCloseHowToUseZinkiDialog = () => {
    setOpenHowToUseZinkiDialog(false);
  };

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
    setOnboardingData("tooltipTuto", "docId", true);
  };

  return (
    <>
      <div className="how-to-use-zinki-container">
        {openTooltip && (
          <CustomTooltip
            open={openTooltip}
            page="workspace"
            action="videoTutorial"
            onclose={handleCloseTooltip}
          />
        )}
        <Chip
          id="how-to-use-zinki"
          label={t("HowToUseZinkiButtonLabel")}
          avatar={
            <HowToUseZinkiIcon
              path={mdiPlayCircleOutline}
              size={0.8}
              // color="var(--primary-main-color)"
            />
          }
          onClick={handleOpenHowToUseZinkiDialog}
          className="how-to-use-zinki-button"
        />
      </div>
      <AppTutorialVideo
        onApply={handleCloseHowToUseZinkiDialog}
        onClose={handleCloseHowToUseZinkiDialog}
        open={openHowToUseZinkiDialog}
      />
    </>
  );
};
export default HowToUseZinki;
