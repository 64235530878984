export default {
  STAGE: "stage",
  LAYER: "layer",
  IMAGE: "image",
  GROUP: "group",
  SHAPE: "shape",
  LINE: "line",
  BUTTON: "button",
  CIRCLE: "circle",
  NONE: "none",
};
