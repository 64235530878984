import Keycloak from "keycloak-js";
import crypto from "crypto-js";
import config from "./settings";

function base64URLEncode(str) {
  return str
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
}
var verifier = base64URLEncode(crypto.lib.WordArray.random(32).toString());

export function sha256() {
  return crypto.algo.SHA512.create()
    .update(crypto.enc.Hex.parse(verifier))
    .finalize()
    .toString();
}
var challenge = sha256();
const keycloak = new Keycloak({
  url: config.REACT_APP_KEYCLOAK_URL,
  realm: config.REACT_APP_KEYCLOAK_REALM,
  redirectUri: window.location.origin,
  clientId: config.REACT_APP_KEYCLOAK_CLIENT_ID,
  responseType: "code",
  strictDiscoveryDocumentValidation: true,
  scope: "openid",
  code_challenge: challenge,
  code_challenge_method: "S256",
});

export default keycloak;
