import { clarity } from "clarity-js";
import jwt_decode from "jwt-decode";
import config from "./settings";

const projectId = config.REACT_APP_CLARITY_PROJECT_ID;

clarity.start({
  projectId: projectId,
  upload: "https://m.clarity.ms/collect",
  track: true,
  content: true,
});

let token = localStorage.getItem("accessToken");
if (token) {
  var decoded = jwt_decode(token);
  let userId = decoded.sub;
  let userHint = decoded.email;

  clarity.identify(userId, userHint);
}

window.addEventListener("message", function (event) {
  if (event.data == "en" || event.data == "ar") {
    console.info("Zinki Message received from the parent: " + event.data);
    let setting = {
      direction: event.data === "ar" ? "rtl" : "ltr",
      responsiveFontSizes: true,
      language: event.data,
    };
    window.localStorage.setItem("settings", JSON.stringify(setting));
    window.localStorage.setItem("portallang", JSON.stringify(setting));
  }
});
