import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { MenuItem, Button, Menu, Typography } from "@mui/material";
import Icon from "@mdi/react";
import { mdiMenuDown } from "@mdi/js";
import { mdiAccountCircle } from "@mdi/js";
import { mdiLogout } from "@mdi/js";
import { DialogLeavingPage } from "../../dialogs/DialogLeavingPage";
import useData from "../../../hooks/useData";
import useAuth from "../../../hooks/useAuth";
import hasAnyAuthority from "../../../utils/private-route";
import "../../../assets/styles/header.css";

export const useStyles = makeStyles((theme) => ({
  root: {
    color: `${theme.palette.primary.main} !important`,
    padding: "16px 20px!important",
    fontSize: "16px!important",
  },
}));

const Profile = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const zinkiAppAdmin = hasAnyAuthority(["ZINKI_APP_ADMIN"]);

  const { user, doLogout } = useAuth();
  const { pageView, canShowDialogLeavingPage, image } = useData();

  const anchorRef = useRef(null);

  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [url, setUrl] = useState(null);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };
  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleNavigateToUrl = async (url) => {
    if (
      pageView === "editor-view" &&
      canShowDialogLeavingPage === image?.data?.id
    ) {
      setOpenConfirmDialog(canShowDialogLeavingPage === image?.data?.id);
    }
    if (
      !canShowDialogLeavingPage ||
      canShowDialogLeavingPage !== image?.data?.id ||
      pageView !== "editor-view"
    ) {
      confirmNavigation(url);
    }
    setUrl(url);
  };

  const confirmNavigation = async (url) => {
    if (url === "/account") {
      await navigate(url);
    }
    if (url === "/logout") {
      await navigate("/workspace");
      await localStorage.setItem("foldersPagination", JSON.stringify({}));
      await localStorage.setItem("receivedObjectsProgress", JSON.stringify({}));
      await doLogout();
    }
  };

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={handleMenuOpen}
        startIcon={<Icon path={mdiAccountCircle} size={1} />}
        endIcon={<Icon path={mdiMenuDown} size={1} />}
      >
        <Typography className="profile-button-email-label">
          {user?.data?.email}
        </Typography>
      </Button>
      <Menu
        id="select-profile-options"
        elevation={4}
        anchorEl={anchorRef.current}
        open={openMenu}
        labelId="demo-simple-select-label"
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          id="button-navivate-to-my-account"
          value={"account"}
          onClick={() => handleNavigateToUrl("/account")}
          classes={{ root: classes.root }}
        >
          <Icon
            path={mdiAccountCircle}
            size={1}
            className="menuItem-profile-item-icon"
          />
          <Typography>{t("account")}</Typography>
          <div
            className={
              user?.data?.offerName === "free"
                ? "header-profile-free-type"
                : "header-profile-premium-type"
            }
          >
            {zinkiAppAdmin && (
              <Typography
                variant="caption"
                className="header-profile-type-typography"
              >
                {user?.data?.offerName}
              </Typography>
            )}
          </div>
        </MenuItem>
        <MenuItem
          id="MenuItem-logout"
          value={"logout"}
          onClick={() => handleNavigateToUrl("/logout")}
          classes={{ root: classes.root }}
        >
          <Icon path={mdiLogout} size={1} className="menuItem-profile-icons" />
          <Typography>{t("logout")}</Typography>
        </MenuItem>
      </Menu>

      <DialogLeavingPage
        showDialog={openConfirmDialog}
        setShowDialog={setOpenConfirmDialog}
        confirmNavigation={() => confirmNavigation(url)}
        cancelNavigation={() => setOpenConfirmDialog(false)}
      />
    </>
  );
};
export default Profile;
