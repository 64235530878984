import { Client } from "@stomp/stompjs";
import config from "../../utils/settings";
class StompClientService {
  static instance = null;

  constructor() {
    if (StompClientService.instance) {
      return StompClientService.instance;
    }
    StompClientService.instance = this;

    this.client = null;
    this.subscriptions = {};

    this.initializeClient();
    this.setupConnectivityListener();
  }

  initializeClient() {
    this.client = new Client({
      brokerURL: config.REACT_APP_BROKER_URL,
      connectHeaders: {
        login: config.REACT_APP_BROKER_USERNAME,
        passcode: config.REACT_APP_BROKER_PASSWORD,
      },
      // reconnectDelay: 1000,
      onConnect: () => {
        this.resubscribeToAll();
      },
      onStompError: (frame) => {
        console.error("STOMP Error:", frame);
      },
      onWebSocketClose: () => {
        // No immediate action on WebSocket close as reconnection is handled by the client
      },
      onChangeState: (state) => {},
      onDisconnect: () => {},
      // debug: (str) => {
      // }
    });

    this.client.activate();
  }

  setupConnectivityListener() {
    window.addEventListener("online", () => {
      this.reconnectIfDisconnected();
    });

    window.addEventListener("offline", () => {});
  }

  reconnectIfDisconnected() {
    if (!this.client.connected) {
      this.client.activate();
    }
  }

  subscribe(destination, callback) {
    if (this.client.connected) {
      const headers = {
        "auto-delete": "true", // Custom header for auto-delete
        durable: "false", // Non-durable queue
        exclusive: "false",
      };
      const subscription = this.client.subscribe(
        destination,
        (message) => {
          const messageBody = JSON.parse(message.body);
          callback(messageBody);
        },
        headers
      );

      this.subscriptions[destination] = {
        subscription: subscription,
        callback: callback,
      };
    } else {
      console.error("Cannot subscribe when STOMP client is disconnected.");
    }
  }

  resubscribeToAll() {
    Object.keys(this.subscriptions).forEach((destination) => {
      const { callback } = this.subscriptions[destination];
      this.subscribe(destination, callback);
    });
  }

  unsubscribe(destination) {
    const subscriptionObj = this.subscriptions[destination];
    if (subscriptionObj && subscriptionObj.subscription) {
      subscriptionObj.subscription.unsubscribe();
      delete this.subscriptions[destination];
    }
  }

  sendMessage(destination, body, headers = {}) {
    if (!this.client || !this.client.connected) {
      console.error("Cannot send message when STOMP client is disconnected.");
      return;
    }

    this.client.publish({
      destination,
      headers,
      body: JSON.stringify(body),
    });
  }

  deactivate() {
    if (this.client) {
      this.client.deactivate();
    }
  }
}

export default StompClientService;
