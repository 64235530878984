import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useData from "../../hooks/useData";
import usePage from "../../hooks/usePage";

export const DialogLeavingPage = ({
  showDialog,
  setShowDialog,
  cancelNavigation,
  confirmNavigation,
}) => {
  const { t } = useTranslation();

  const { setMode, pageView, setCanShowDialogLeavingPage } = useData();
  const { setEditBaselines, setAddBaselines } = usePage();

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const confirmNavigationYesButton = () => {
    confirmNavigation();
    setShowDialog(false);
    setEditBaselines(false);
    setAddBaselines(false);
    setMode("editText");
    if (pageView === "editor-view") {
      setCanShowDialogLeavingPage(null);
      localStorage.setItem("editedLines", JSON.stringify([]));
    }
  };

  return (
    <Dialog
      fullWidth
      open={showDialog}
      onClose={handleDialogClose}
      PaperProps={{ className: "dialog-paper-leaving-page" }}
    >
      <DialogTitle>
        <Typography variant="h2">{t("youHaveUnsavedChanges")}</Typography>
      </DialogTitle>
      <DialogContent className="dialog-content-root-leaving-page">
        <Typography variant="body2">
          {t("yourChangesWillBeLostIfYouLeaveThePageWithoutSaving")}
        </Typography>
      </DialogContent>
      <DialogActions className="dialog-actions-leaving-page">
        <Button
          id="button-confirm-deleteDialog"
          variant="outlined"
          color="primary"
          onClick={confirmNavigationYesButton}
        >
          {t("leave")}
        </Button>
        <Button variant="contained" color="primary" onClick={cancelNavigation}>
          {t("stayOnThePage")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
