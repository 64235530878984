export default {
  fontFamily: "Readex Regular",

  h1: {
    fontSize: 36,
    fontWeight: "400",
    color: "#2A3072",
    lineHeight: "1.7",
  },
  h2: {
    fontSize: 28,
    fontWeight: "400",
    color: "#2A3072",
    lineHeight: "1.7",
  },
  h3: {
    fontSize: 22,
    fontWeight: "400",
    color: "#2A3072",
    lineHeight: "1.7",
  },
  h4: {
    fontSize: 22,
    fontWeight: "400",
    color: "#000000",
    lineHeight: "1.7",
  },
  h5: {
    fontSize: 14,
    fontWeight: "400",
    color: "#00000099",
    lineHeight: "1.7",
  },
  h6: {
    fontSize: 14,
    fontWeight: "400",
    color: "#00000061",
    lineHeight: "1.7",
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: "700",
    color: "#000000DE",
    lineHeight: "1.6",
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: "400",
    color: "rgb(0 0 0 / 70%)",
    lineHeight: "1.6",
  },
  body1: {
    fontSize: 18,
    fontWeight: "400",
    color: "#000000DE",
    lineHeight: "1.6",
  },
  body2: {
    fontSize: 16,
    fontWeight: "400",
    color: "#000000DE",
    lineHeight: "1.6",
  },
  caption: {
    fontSize: 12,
    fontWeight: "400",
    color: "#9E9E9E",
    lineHeight: "1.6",
  },
};
