import React, { createContext, useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import axios from "axios";
import { Typography } from "@mui/material";
import useData from "../hooks/useData";
import config from "../utils/settings";
import { LineApi } from "../services/http";

const initialDataState = {
  displayPolygons: false,
  displayRegions: false,
  displayBaselines: false,
  editBaselines: false,
  addBaselines: false,
  selectedAnchorPoints: [],
  imageBase64: null,
  polygons: [],
  regions: [],
  spellings: [],
  editSegmentationAction: null,
  history: [],
  redoStack: [],
  stageZoomLevel: {},
  selectedLine: {},
  newBaselines: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DISPLAY_POLYGONS": {
      return {
        ...state,
        displayPolygons: action.payload,
      };
    }
    case "SET_DISPLAY_REGIONS": {
      return {
        ...state,
        displayRegions: action.payload,
      };
    }
    case "SET_DISPLAY_BASELINES": {
      return {
        ...state,
        displayBaselines: action.payload,
      };
    }
    case "SET_EDIT_BASELINES": {
      return {
        ...state,
        editBaselines: action.payload,
      };
    }
    case "SET_ADD_BASELINES": {
      return {
        ...state,
        addBaselines: action.payload,
      };
    }
    case "SET_SELECTED_LINE": {
      return {
        ...state,
        selectedLine: action.payload,
      };
    }
    case "SET_BASELINES": {
      return {
        ...state,
        baselines: action.payload,
      };
    }
    case "SET_POLYGONS": {
      return {
        ...state,
        polygons: action.payload,
      };
    }
    case "SET_REGIONS": {
      return {
        ...state,
        regions: action.payload,
      };
    }
    case "SELECT_ANCHOR_POINTS": {
      return {
        ...state,
        selectedAnchorPoints: action.payload,
      };
    }
    case "SET_IMAGE_BASE64": {
      return {
        ...state,
        imageBase64: action.payload,
      };
    }
    case "GET_SPELLING_TEXT": {
      const { spellings } = action.payload;
      return {
        ...state,
        spellings,
      };
    }
    case "SET_EDIT_SEGMENTATION_ACTION": {
      return {
        ...state,
        editSegmentationAction: action.payload,
      };
    }
    case "SET_HISTORY": {
      return {
        ...state,
        history: action.payload,
      };
    }
    case "SET_REDO_STACK": {
      return {
        ...state,
        redoStack: action.payload,
      };
    }
    case "SET_STAGE_ZOOM_LEVEL": {
      return {
        ...state,
        stageZoomLevel: action.payload,
      };
    }
    case "SET_NEW_BASELINES": {
      return {
        ...state,
        newBaselines: [...state.newBaselines, action.payload],
      };
    }
    case "UPDATE_NEW_BASELINE": {
      return {
        ...state,
        newBaselines: action.payload,
      };
    }
    case "REMOVE_NEW_BASELINES": {
      // Find the index of the baseline that matches baselineToRemove
      const index = state.newBaselines.findIndex(
        (pair) => JSON.stringify(pair) === JSON.stringify(action.payload)
      );

      // If the baseline is found, remove it from the newBaselines
      if (index !== -1) {
        state.newBaselines.splice(index, 1);
      }
    }

    default: {
      return { ...state };
    }
  }
};
const PageContext = createContext({
  ...initialDataState,
  setDisplayPolygons: () => Promise.resolve(),
  setDisplayRegions: () => Promise.resolve(),
  setDisplayBaselines: () => Promise.resolve(),
  setEditBaselines: () => Promise.resolve(),
  setAddBaselines: () => Promise.resolve(),
  setSelectedLine: () => Promise.resolve(),
  setBaselines: () => Promise.resolve(),
  selectAnchorPoints: () => Promise.resolve(),
  setImageBase64ToEditSegmentation: () => Promise.resolve(),
  setPolygons: () => Promise.resolve(),
  setRegions: () => Promise.resolve(),
  addNewBaseline: () => Promise.resolve(),
  deleteBaseline: () => Promise.resolve(),
  getSpellingText: () => Promise.resolve(),
  resetSpellings: () => Promise.resolve(),
  setEditSegmentationAction: () => Promise.resolve(),

  setHistory: () => Promise.resolve(),
  setRedoStack: () => Promise.resolve(),
  setAction: () => Promise.resolve(),
  undo: () => Promise.resolve(),
  redo: () => Promise.resolve(),
  updateLineRotationStatus: () => Promise.resolve(),
  setStageZoomLevel: () => Promise.resolve(),
  updateSelectedBaseline: () => Promise.resolve(),
  setNewBaselines: () => Promise.resolve(),
  removeNewBaselines: () => Promise.resolve(),
  updateNewBaseline: () => Promise.resolve(),
  initiateNewBaselinesState: () => Promise.resolve(),
});
export const PageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialDataState);
  const {
    getLines,
    highlightTheCurrentMask,
    setCanShowDialogLeavingPage,
    lines,
    setLines,
    addLine,
    updateLine,
  } = useData();

  const setHistory = (array) => {
    dispatch({
      type: "SET_HISTORY",
      payload: array,
    });
  };
  const setRedoStack = (array) => {
    dispatch({
      type: "SET_REDO_STACK",
      payload: array,
    });
  };
  const setAction = () => {
    setHistory([...state.history, lines]);
    setRedoStack([]);
  };

  const undo = () => {
    if (state.history.length > 0) {
      const previousState = state.history[state.history.length - 1];
      setHistory(state.history.slice(0, -1));
      setRedoStack([lines, ...state.redoStack]);
      setLines(previousState);
      setEditSegmentationAction("undo");
    }
  };
  const redo = () => {
    if (state.redoStack.length > 0) {
      const nextState = state.redoStack[0];
      setRedoStack(state.redoStack.slice(1));
      setHistory([...state.history, lines]);
      setLines(nextState);
      setEditSegmentationAction("redo");
    }
  };

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const getSnackbar = (message, status, description) => {
    enqueueSnackbar(
      <Typography variant="body2" className="snackbar-msg">
        {t(message)}
        {description ? (
          <>
            <br />
            {t(description)}
          </>
        ) : null}
      </Typography>,
      {
        variant: status,
        autoHideDuration:
          status === "error" ||
          message === "successUploadFile" ||
          message === "successTranscriptionPages"
            ? null
            : 5000,
      }
    );
  };
  const setEditSegmentationAction = (action) => {
    dispatch({
      type: "SET_EDIT_SEGMENTATION_ACTION",
      payload: action,
    });
  };
  const setDisplayPolygons = (booleen) => {
    dispatch({
      type: "SET_DISPLAY_POLYGONS",
      payload: booleen,
    });
    localStorage.setItem("displayPolygons", JSON.stringify(booleen));
  };
  const setDisplayRegions = (booleen) => {
    dispatch({
      type: "SET_DISPLAY_REGIONS",
      payload: booleen,
    });
    localStorage.setItem("displayRegions", JSON.stringify(booleen));
  };
  const setDisplayBaselines = (booleen) => {
    dispatch({
      type: "SET_DISPLAY_BASELINES",
      payload: booleen,
    });
    localStorage.setItem("displayBaselines", JSON.stringify(booleen));
  };
  const setEditBaselines = (booleen) => {
    dispatch({
      type: "SET_EDIT_BASELINES",
      payload: booleen,
    });
    localStorage.setItem("editBaselines", JSON.stringify(booleen));
  };
  const setAddBaselines = (booleen) => {
    dispatch({
      type: "SET_ADD_BASELINES",
      payload: booleen,
    });
  };
  const setSelectedLine = (line) => {
    dispatch({
      type: "SET_SELECTED_LINE",
      payload: line,
    });
  };
  const setBaselines = (array) => {
    dispatch({
      type: "SET_BASELINES",
      payload: array,
    });
  };
  const setPolygons = (array) => {
    dispatch({
      type: "SET_POLYGONS",
      payload: array,
    });
  };
  const setRegions = (array) => {
    dispatch({
      type: "SET_REGIONS",
      payload: array,
    });
  };

  const selectAnchorPoints = (array) => {
    dispatch({
      type: "SELECT_ANCHOR_POINTS",
      payload: array,
    });
  };

  const updateSelectedBaseline = async (
    imageId,
    lineId,
    imageBase64,
    selectedLine
  ) => {
    setAction();
    let body = {
      image: imageBase64,
      newBaseline: selectedLine.baseline,
      order: selectedLine.order,
    };
    setEditSegmentationAction("editBaseline");
    await axios
      .put(
        `${config.REACT_APP_BASE_URL}/folder/line/baseline/${lineId}/${imageId}`,
        body
      )
      .then(async (response) => {
        setCanShowDialogLeavingPage(imageId);
        setEditSegmentationAction(null);
      })
      .catch(async (err) => {
        getSnackbar("errorEditBaseLine", "error");
        setEditSegmentationAction(null);
      });
  };

  const setImageBase64ToEditSegmentation = (string) => {
    dispatch({
      type: "SET_IMAGE_BASE64",
      payload: string,
    });
  };

  const addNewBaseline = async (
    folderId,
    documentId,
    imageId,
    imageBase64,
    newBaseline,
    updatedBaselines,
    isFlipped
  ) => {
    setAction();
    setEditSegmentationAction("addNewBaseline");
    let body = {
      image: imageBase64,
      newBaseline,
      isFlipped,
    };
    await axios
      .post(
        `${config.REACT_APP_BASE_URL}/folder/line/baseline/${imageId}`,
        body
      )
      .then(async (response) => {
        setCanShowDialogLeavingPage(imageId);
        let newLine = {
          text: response.data.line.text || "",
          id: response.data.line._id,
          polygon: response.data.line.polygon,
          index: response.data.line.index,
          order: response.data.line.order,
          imageID: response.data.line.imageID,
          baseline: response.data.line.baseline,
          history: response.data.line.history,
          isFlipped: response.data.line.isFlipped,
        };
        addLine(newLine);
        removeNewBaselines(newBaseline);
        setEditSegmentationAction(null);
      })
      .catch(async (err) => {
        removeNewBaselines(newBaseline);
        getSnackbar("errorAddNewBaseLine", "error");
        setEditSegmentationAction(null);
      });
  };

  const deleteBaseline = async (folderId, documentId, imageId, lineId) => {
    setEditSegmentationAction("deleteBaseline");
    setSelectedLine({});
    highlightTheCurrentMask([]);
    const newList = lines
      .filter((item) => item.id !== lineId)
      .map((line, index) => {
        return {
          text: line.text || "",
          id: line.id,
          polygon: line.polygon,
          index: line.index,
          order: index,
          imageID: line.imageID,
          baseline: line.baseline,
          history: line.history,
          isFlipped: line.isFlipped ?? false,
        };
      });
    setLines(newList);
    await axios
      .delete(
        `${config.REACT_APP_BASE_URL}/folder/line/baseline/${lineId}/${imageId}`
      )
      .then(async (response) => {
        setCanShowDialogLeavingPage(imageId);
        setEditSegmentationAction(null);
      })
      .catch(async (err) => {
        getLines(folderId, documentId, imageId, "getLinesAfterEdirBaseline");
        setEditSegmentationAction(null);
        getSnackbar("errorDeleteBaseLine", "error");
      });
  };

  const updateLineRotationStatus = async (selectedLine) => {
    setEditSegmentationAction("updateLineRotationStatus");
    LineApi.updateLineRotationStatus(
      selectedLine?.id,
      selectedLine?.imageID,
      selectedLine?.isFlipped
    )
      .then((response) => {
        setCanShowDialogLeavingPage(selectedLine.imageID);
        setEditSegmentationAction(null);
        updateLine(response.line);
      })
      .catch(async (err) => {
        setEditSegmentationAction(null);
      })
      .finally(() => {
        setEditSegmentationAction(null);
      });
  };

  const setStageZoomLevel = (object) => {
    dispatch({
      type: "SET_STAGE_ZOOM_LEVEL",
      payload: object,
    });
  };
  const setNewBaselines = (baseline) => {
    dispatch({
      type: "SET_NEW_BASELINES",
      payload: baseline,
    });
  };
  const updateNewBaseline = (baseline) => {
    const updatedNewBaselines = [...state.newBaselines];
    updatedNewBaselines[updatedNewBaselines.length - 1] = baseline;
    dispatch({
      type: "UPDATE_NEW_BASELINE",
      payload: updatedNewBaselines,
    });
  };
  const removeNewBaselines = (baseline) => {
    dispatch({
      type: "REMOVE_NEW_BASELINES",
      payload: baseline,
    });
  };

  const initiateNewBaselinesState = () => {
    state.newBaselines = [];
  };

  useEffect(() => {
    const displayPolygons = JSON.parse(localStorage.getItem("displayPolygons"));
    const displayRegions = JSON.parse(localStorage.getItem("displayRegions"));
    const displayBaselines = JSON.parse(
      localStorage.getItem("displayBaselines")
    );
    const editBaselines = JSON.parse(localStorage.getItem("editBaselines"));

    dispatch({
      type: "SET_DISPLAY_POLYGONS",
      payload: displayPolygons,
    });
    dispatch({
      type: "SET_DISPLAY_REGIONS",
      payload: displayRegions,
    });
    dispatch({
      type: "SET_DISPLAY_BASELINES",
      payload: displayBaselines,
    });
    dispatch({
      type: "SET_EDIT_BASELINES",
      payload: editBaselines,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <PageContext.Provider
      value={{
        ...state,
        setDisplayPolygons,
        setDisplayRegions,
        setDisplayBaselines,
        setEditBaselines,
        setAddBaselines,
        setSelectedLine,
        setBaselines,
        selectAnchorPoints,
        setImageBase64ToEditSegmentation,
        setPolygons,
        setRegions,
        addNewBaseline,
        deleteBaseline,
        setEditSegmentationAction,
        setHistory,
        setRedoStack,
        setAction,
        undo,
        redo,
        updateLineRotationStatus,
        setStageZoomLevel,

        updateSelectedBaseline,
        setNewBaselines,
        removeNewBaselines,
        updateNewBaseline,
        initiateNewBaselinesState,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageContext;
