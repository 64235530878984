import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { AppBar, Toolbar, Button, Typography, Link, Box } from "@mui/material";
import Icon from "@mdi/react";
import { mdiFolderHome } from "@mdi/js";
import { mdiHomeSearch } from "@mdi/js";

import SelectLanguage from "./SelectLanguage";
import Profile from "./Profile";
import useAuth from "../../../hooks/useAuth";
import useData from "../../../hooks/useData";
import hasAnyAuthority from "../../../utils/private-route";
import "../../../assets/styles/header.css";
import { DialogLeavingPage } from "../../dialogs/DialogLeavingPage";
import config from "../../../utils/settings";

const Logo = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${theme.logo})`,
  backgroundRepeat: "no-repeat",
  width: theme.clientId !== "zinki" ? 140 : 95,
  height: 40,
  backgroundSize: "contain",
  backgroundPositionX: "left",
}));

const Header = () => {
  const zinkiAppAdmin = hasAnyAuthority(["ZINKI_APP_ADMIN"]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const {
    getFolders,
    goToWorkspace,
    pageView,
    canShowDialogLeavingPage,
    image,
  } = useData();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [url, setUrl] = useState(null);

  const handleNavigateToUrl = async (url) => {
    if (
      pageView === "editor-view" &&
      canShowDialogLeavingPage === image?.data?.id
    ) {
      setOpenConfirmDialog(canShowDialogLeavingPage === image?.data?.id);
    }
    if (
      !canShowDialogLeavingPage ||
      canShowDialogLeavingPage !== image?.data?.id ||
      pageView !== "editor-view"
    ) {
      confirmNavigation(url);
    }
    setUrl(url);
  };

  const confirmNavigation = async (url) => {
    if (url === "/search") {
      window.location.href = config.REACT_APP_SEBR_URL;
    }
    if (url && url !== "/search") {
      await navigate(url);
    }
    if (url === "/workspace") {
      await goToWorkspace(true);
      await getFolders(1, 25, "", "myFolders");
    }
  };

  const reducer = (accumulator, curr) => accumulator + curr;

  return (
    <>
      <AppBar
        position="static"
        elevation={1}
        className={
          window.location.href.includes("from=portal")
            ? "header-appBar-portal"
            : "header-appBar"
        }
      >
        <Toolbar className="header-toolbar">
          <div className="header-logo">
            <Button
              id="logoButton-workspace"
              onClick={() => handleNavigateToUrl("/workspace")}
              className="header-logo-button"
            >
              <Logo />
            </Button>
          </div>
          <div className="header-buttons">
            {!zinkiAppAdmin && (
              <Button
                id="button-go-to-sebr-search"
                onClick={() => handleNavigateToUrl("/search")}
                color="primary"
                startIcon={<Icon path={mdiHomeSearch} size={1} />}
                className="button-go-to-sebr-search"
              >
                <span className="button-go-to-sebr-search-label">
                  {t("searchPage")}
                </span>
              </Button>
            )}
            <Button
              id="button-workspace"
              onClick={() => handleNavigateToUrl("/workspace")}
              color="primary"
              startIcon={<Icon path={mdiFolderHome} size={1} />}
              className="button-workspace"
            >
              <span className="button-workspace-label">{t("workspace")}</span>
            </Button>
            <SelectLanguage />
            <Profile />
          </div>
        </Toolbar>
      </AppBar>
      {user?.data?.offerName === "free" && (
        <div className="header-upgrade-alert">
          <Typography
            variant="body2"
            className="header-upgrade-alert-typography"
          >
            {t("yourAccountIsFreeYouCan")}
          </Typography>
          <Link
            id="link-upgrade-topbar"
            variant="body2"
            color="primary"
            onClick={() => handleNavigateToUrl("/price-simulator")}
            className="header-upgrade-alert-link"
          >
            {t("upgrade")}
          </Link>
        </div>
      )}
      {user?.data?.offerName === "premium" &&
        user?.data?.totalQuotaDetails?.map((el) => el.quota).reduce(reducer) ===
          0 && (
          <div className="header-upgrade-alert">
            <Typography
              variant="body2"
              className="header-upgrade-alert-typography"
            >
              {t("youCantTranscribePagesAnymore")}
            </Typography>
            <Link
              id="link-upgrade-topbar"
              variant="body2"
              color="primary"
              onClick={() => handleNavigateToUrl("/price-simulator")}
              className="header-upgrade-alert-link"
            >
              {t("upgrade")}
            </Link>
          </div>
        )}
      <DialogLeavingPage
        showDialog={openConfirmDialog}
        setShowDialog={setOpenConfirmDialog}
        confirmNavigation={() => confirmNavigation(url)}
        cancelNavigation={() => setOpenConfirmDialog(false)}
      />
    </>
  );
};
export default Header;
