import React from "react";
import { LinearProgress, Box, SvgIcon } from "@mui/material";
import { styled } from "@mui/material/styles";
import "../../assets/styles/components.css";

const SplashScreenContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.primary.spalshScreenBackground,
  height: "100vh",
  width: "100%",
  position: "fixed",
  left: 0,
  top: 0,
  position: "relative",
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  width: 280,
  height: 100,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 4,
}));
const Logo = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${theme.logo})`,
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: theme.clientId !== "zinki" ? 40 : 65,
  backgroundSize: "contain",
  backgroundPositionX: "center",
  // padding:16
}));
const PoweredByZinki = styled(Box)(({ theme }) => ({
  display: theme.clientId === "zinki" && "none",
  backgroundImage:
    theme.direction === "rtl"
      ? `url(/static/PoweredBy-AR-Vertical-OnPrimary.svg)`
      : `url(/static/PoweredBy-ENG-Vertical-OnPrimary.svg)`,
  backgroundRepeat: "no-repeat",
  width: 120,
  height: 65,
  backgroundSize: "contain",
  backgroundPositionX: "center",
  position: "absolute",
  bottom: 40,
}));

const SplashScreen = () => {
  return (
    <SplashScreenContainer>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <LinearProgress className="slash-screen-linear-progress" />
      <PoweredByZinki />
    </SplashScreenContainer>
  );
};

export default SplashScreen;
