import axios from "axios";

import ENDPOINTS from "./endpoints";

const UploadApi = {
  async createFolder(body) {
    try {
      const url = ENDPOINTS.CREATE_FOLDER();
      const response = await axios.post(url, body);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default UploadApi;
