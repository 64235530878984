export function getCommonElements(arr1, arr2) {
  let common = [];
  for (var i = 0; i < arr1?.length; ++i) {
    for (var j = 0; j < arr2?.length; ++j) {
      if (arr1[i] === arr2[j]) {
        common.push(arr1[i]);
      }
    }
  }

  return common;
}

// DataContext (lines)
export function mergeArrayByUpdatedAt(array) {
  const resultMap = new Map();

  array.forEach((subArray) => {
    subArray.forEach((item) => {
      const { updatedAt, text, updatedBaseline, updatedPolygon } = item;
      if (!resultMap.has(updatedAt)) {
        resultMap.set(updatedAt, { updatedAt, lines: [item] });
      } else {
        resultMap.get(updatedAt).lines.push(updatedAt);
      }
    });
  });

  return Array.from(resultMap.values());
}

export function arraysAreDifferent(array1, array2) {
  if (array1.length !== array2.length) {
    return true;
  }

  const newArray1 = array1.slice();
  const newArray2 = array2.slice();

  for (let i = 0; i < newArray1.length; i++) {
    if (newArray1[i] !== newArray2[i]) {
      return true;
    }
  }

  return false;
}
export function findLargestSubArray(arrays) {
  let largestArray = [];
  let maxLength = 0;

  for (let subArray of arrays) {
    if (subArray.length > maxLength) {
      largestArray = subArray;
      maxLength = subArray.length;
    }
  }

  return largestArray;
}
