import * as React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Typography, Link } from "@mui/material";
import HowToUseZinki from "./HowToUseZinki";
import useSettings from "../../hooks/useSettings";

const useStyles = makeStyles((theme) => ({
  footerLink: {
    margin: "0 16px !important",
  },
  copyright: {
    display: theme.clientId !== "zinki" && "none",
  },
  logopoweredBy: {
    display: theme.clientId === "zinki" && "none",
    backgroundImage:
      theme.direction === "rtl"
        ? `url("/static/PoweredBy-AR-Horizontal-OnLight.svg")`
        : `url("/static/PoweredBy-ENG-Horizontal-OnLight.svg")`,
    backgroundRepeat: "no-repeat",
    width: 95,
    height: 45,
    backgroundSize: "contain",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { settings } = useSettings();

  return (
    <div component="footer" className="footer-container">
      <div className="footer-links">
        <Link
          id="link-privacy-policy"
          href={
            settings.language === "ar"
              ? "https://zinki.ai/privacy-policy-ar/"
              : "https://zinki.ai/privacy-policy/"
          }
          variant="h6"
          className={classes.footerLink}
        >
          {t("privacyPolicy")}
        </Link>
        <Link
          id="link-terms-of-services"
          href={
            settings.language === "ar"
              ? "https://zinki.ai/terms-of-service-ar/"
              : "https://zinki.ai/terms-of-service/"
          }
          className={classes.footerLink}
          variant="h6"
        >
          {t("termsOfService")}
        </Link>
        <HowToUseZinki />
      </div>
      <Typography variant="caption" className={classes.copyright}>
        {t("copyright@revampr")}
      </Typography>
      <div className={classes.logopoweredBy}></div>
    </div>
  );
};
export default Footer;
