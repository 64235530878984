import { useEffect } from "react";
import useData from "./useData";

const useUnload = () => {
  const { disconnectFromStomp } = useData();
  useEffect(() => {
    // Function to handle the unload event
    const handleUnload = async (event) => {
      // Call the provided cleanup function
      await disconnectFromStomp();
    };

    // Add the event listener for 'beforeunload'
    window.addEventListener("beforeunload", handleUnload);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []); // Dependency array ensures the effect is rerun if the cleanup function changes
};

export default useUnload;
