import config from "../../../utils/settings";

export default {
  UPDATE_TEXT: (folderId) =>
    `${config.REACT_APP_BASE_URL}/folder/${folderId}/image/updateText`,
  TASHKEEL_TEXT: () => `${config.REACT_APP_BASE_URL}/document/tashkeel`,
  TASHKEEL_TEXT: () => `${config.REACT_APP_BASE_URL}/document/tashkeel`,
  LINE_HISTORY: (folderId, imageId) =>
    `${config.REACT_APP_BASE_URL}/folder/${folderId}/image/${imageId}/lines/history`,
  LINES: (folderId, documentId, imageId) =>
    `${config.REACT_APP_BASE_URL}/folder/${folderId}/document/${documentId}/image/${imageId}/line`,
  RESTORE_VERSION: (folderId) =>
    `${config.REACT_APP_BASE_URL}/folder/${folderId}/image/restoreVersion`,
  ADD_BASELINE: (imageId) =>
    `${config.REACT_APP_BASE_URL}/folder/line/baseline/${imageId}`,
  EDIT_BASELINE: (lineId, imageId) =>
    `${config.REACT_APP_BASE_URL}/folder/line/baseline/${lineId}/${imageId}`,
  DELETE_BASELINE: (lineId, imageId) =>
    `${config.REACT_APP_BASE_URL}/folder/line/baseline/${lineId}/${imageId}`,
  UPDATE_LINE_ROTATION_STATUS: (lineId, imageId, isFlipped) =>
    `${config.REACT_APP_BASE_URL}/folder/line/baseline/${lineId}/${imageId}/${isFlipped}`,
};
