export function loadSvgImage(svgData) {
  const img = new window.Image();
  const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
  const url = URL.createObjectURL(svgBlob);

  img.src = url;
  img.onload = () => {
    URL.revokeObjectURL(url);
  };
  return img;
}

export function getImageDimensions(imageUrl) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;

    // Once the image loads, resolve the dimensions
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      resolve({ width, height });
    };

    // Handle errors (e.g., if the image URL is invalid)
    img.onerror = () => {
      reject("Failed to load image");
    };
  });
}
