import React, { Fragment, lazy, Suspense } from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import AuthGuard from "./components/auth/AuthGuard";
import ZinkiLayout from "./views/layout/index";

const routes = [
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("./views/error/NotFoundView")),
    pagetitle: "Error 404",
  },
  {
    exact: true,
    path: "/403",
    component: lazy(() => import("./views/error/UnauthorizedView")),
    pagetitle: "Error 403",
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: ZinkiLayout,
    path: "/workspace",
    component: lazy(() => import("./views/workspace")),
    pagetitle: "Workspace",
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: ZinkiLayout,
    path: "/page-viewer/document/:docId/pages/:index",
    component: lazy(() => import("./views/modernPage")),
    pagetitle: "Page",
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: ZinkiLayout,
    path: "/document/:docId",
    component: lazy(() => import("./views/document")),
    pagetitle: "Document",
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: ZinkiLayout,
    path: "/editor/document/:docId/pages/:index",
    page: "EditorView",
    component: lazy(() => import("./views/pageEditor")),
    pagetitle: "Page editor",
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: ZinkiLayout,
    path: "/account",
    component: lazy(() => import("./views/profile")),
    pagetitle: "Profile",
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: ZinkiLayout,
    path: "/price-simulator",
    page: "price-simulator",
    component: lazy(() => import("./views/payment/PriceSimulator")),
    pagetitle: "Price simulator",
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: ZinkiLayout,
    path: "/payment",
    page: "payment",
    component: lazy(() => import("./views/payment/Payment")),
    pagetitle: "Payment",
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: ZinkiLayout,
    path: "/payment-success",
    page: "payment-success",
    component: lazy(() => import("./views/payment/PaymentSuccess")),
    pagetitle: "Payment success",
  },
  // {
  //   exact: true,
  //   guard: AuthGuard,
  //   layout: ZinkiLayout,
  //   path: "/app",
  //   component: lazy(() => import("./views/workspace")),
  //   pagetitle: "Workspace",
  // },
  {
    exact: true,
    guard: AuthGuard,
    layout: ZinkiLayout,
    path: "/",
    component: () => <Navigate replace={true} to="/workspace" />,
    pagetitle: "Workspace",
  },
  {
    path: "*",
    component: () => <Navigate to="/404" />,
    pagetitle: "Error 404",
  },
];

export default routes;

export const renderRoutes = (routes = []) => (
  <Suspense fallback={false}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            pagetitle={route.pagetitle}
            element={
              <Guard>
                <Layout page={route?.page} pagetitle={route.pagetitle}>
                  <Component pagetitle={route.pagetitle} />
                </Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);
