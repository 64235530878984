export const THEMES = {
  LIGHT: "LIGHT",
  ONE_DARK: "ONE_DARK",
};
export const palette = {
  primary: "primary",
  secondary: "secondary",
  error: "error",
  warning: "warning",
  info: "info",
  success: "success",
};
