import jwt_decode from "jwt-decode";

export const hasAnyAuthority = (hasAnyAuthorities) => {
  let token = localStorage.getItem("accessToken");
  if (token) {
    var decoded = jwt_decode(token);
    if (hasAnyAuthorities.length === 0) {
      return true;
    }
    return hasAnyAuthorities.some((auth) =>
      decoded.realm_access.roles.includes(auth)
    );
  }
  return false;
};

/**
 * A route wrapped in an authentication check so that routing happens only when you are authenticated.
 * Accepts same props as React router Route.
 * The route also checks for authorization if hasAnyAuthorities is specified.
 */
export default hasAnyAuthority;
