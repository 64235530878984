import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  SvgIcon,
  Button,
  Skeleton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { mdiCheck } from "@mdi/js";
import Icons from "../../assets/icons";
import useAuth from "../../hooks/useAuth";
import useData from "../../hooks/useData";

const OnboardingDialog = ({ open, onClose, from, startWatchVideo }) => {
  const { t } = useTranslation();
  const { folder } = useData();
  const { user } = useAuth();

  const [play, setPlay] = useState(false);
  const [url, setUrl] = useState(null);
  const [display, setDisplay] = useState(false);
  open &&
    setTimeout(() => {
      setDisplay(true);
      // old video link https://youtu.be/jviRts5G6Vo
      setUrl("https://youtu.be/fRNN72vPsDM?t=351");
    }, 1000);

  const closeDialogOnBoardingDefinitive = () => {
    onClose();
    startWatchVideo();
  };
  const closeDialogAfterWatchVideo = () => {
    onClose();
    if (
      play &&
      (!user.data.onboardingData.exportOnboarding.isChecked ||
        !user.data.onboardingData.retrainOnboarding.isChecked)
    ) {
      startWatchVideo();
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={closeDialogAfterWatchVideo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ className: "dialog-paper" }}
        scroll="body"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="onboarding-dialog-title">
            <SvgIcon className="onboarding-dialog-svg-icon">
              <Icons.accuracyEnhanceIcon />
            </SvgIcon>
            <Typography variant="h2">{t("getBetterAccuracy")}</Typography>
          </div>
          <IconButton
            id="button-close-deleteDialog"
            onClick={closeDialogAfterWatchVideo}
            className="onboarding-dialog-close-icon-button"
          >
            <Icon path={mdiClose} size={1} />
          </IconButton>
        </DialogTitle>
        <DialogContent className="onboarding-dialog-content-root">
          {from !== "alert" && (
            <Typography variant="body1">
              {t("ThisIsFisrtDraftZinkiCanRecogniseMoreEfficiently")}
            </Typography>
          )}
          {!display ? (
            <Skeleton
              width="100%"
              height="360px"
              animation="wave"
              className={
                from === "alert" ? "react-player-from-alert" : "react-player"
              }
            />
          ) : (
            <ReactPlayer
              url={url}
              playing={!!url}
              onStart={() => setPlay(true)}
              width="100%"
              className={
                from === "alert" ? "react-player-from-alert" : "react-player"
              }
            />
          )}
          <Typography variant="body1">
            {t("youCanTrainZinkiToGetBetterAccuracy")}
          </Typography>
          <Typography variant="body1">
            {`${t("correct")} ${
              folder?.data?.nextSteps.length ? folder?.data?.nextSteps[0] : 8
            } ${t("zinkiWillUseThemAsReferenceToImproveAccuracy")}`}{" "}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={closeDialogOnBoardingDefinitive}
            startIcon={<Icon path={mdiCheck} size={1} />}
            className="dialog-actions-okIUnderstand-button"
          >
            {t("OkIUnderstand")}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default OnboardingDialog;
